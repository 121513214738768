import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

export let history = createBrowserHistory();

ReactGA.initialize(process.env.REACT_APP_GA);

history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});
