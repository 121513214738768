import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
//import { checkIsAuthenticated, authSignUp, authLogin, authLogout } from '../../services/auth'
import { useCookies } from 'react-cookie';
import { history } from '../history';
import { ToastContainer, toast } from 'react-toastify';

const checkIsAuthenticated = async (token, path) => {
  return await fetch(`${process.env.REACT_APP_API_DOMAIN}${path}`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + token,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({}),
  });
};

const authSignUp = async (url, credentials) => {
  return await fetch(`${process.env.REACT_APP_API_DOMAIN}${url}`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({
      ...credentials,
    }),
  });
};

const authLogin = async (url, credentials) => {
  return await fetch(`${process.env.REACT_APP_API_DOMAIN}${url}`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({
      ...credentials,
    }),
  });
};

const authLogout = async (url, token) => {
  return await fetch(`${process.env.REACT_APP_API_DOMAIN}${url}`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': 'Bearer ' + token,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({}),
  });
};

export const AuthContext = React.createContext({});

export function Auth({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(['user_type', 'token']);

  //cookie-1 user_type
  //cookie-2 token

  const [user, setUser] = useState(null); //null| staff| user

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = () => {
    setIsLoading(true);
    //alert(cookies.token)
    const path = cookies.user_type === 'staff' ? '/admin/auth/check' : '/user/auth/check';

    checkIsAuthenticated(cookies.token, path)
      .then(async (response) => {
        const jsonResult = await response.json();
        console.log('jsonResult', jsonResult);

        //alert(jsonResult?.code)

        if (jsonResult?.code !== 200) {
          if(jsonResult?.error === "E_UNAUTHORIZED_ACCESS: ACCOUNT_INACTIVE"){
            alert("Your account is currently under review.")
          }
          throw jsonResult?.errorTrace?.[0]?.message || jsonResult?.error;
        }

        //change loading from true to false
        setIsAuthenticated(true);
        setIsLoading(false);
        // setCookie('token', jsonResult?.token.token, { path: '/' , expires: new Date(Date.now()+2592000) , maxAge:1000000});

        if (typeof jsonResult?.staff !== 'undefined') {
          setCookie('user_type', 'staff', { path: '/' });
          setUser(jsonResult?.staff);
        } else {
          setCookie('user_type', 'user', { path: '/' });
          setUser(jsonResult?.user);
        }
        //alert(1)
      })
      .catch((err) => {
        if (typeof err !== 'string') {
          err = 'Network error';
        }
        

        console.log(err || 'Something went wrong!');
        //change loading from true to false
        setIsLoading(false);
        setIsAuthenticated(false);
        removeCookie('token');
        removeCookie('user_type');
        setUser(null);
      });
  };

  const login = (credentials) => {
    let url = history.location.pathname === '/login' ? '/user/auth/login' : '/admin/auth/login';

    setIsLoading(true);
    authLogin(url, credentials)
      .then(async (response) => {
        const jsonResult = await response.json();
        console.log('jsonResult', jsonResult);

        if (jsonResult?.code !== 200) {
          throw jsonResult?.errorTrace?.[0]?.message || jsonResult?.error;
        }

        //change loading from true to false
        setIsAuthenticated(true);
        setIsLoading(false);
        setCookie('token', jsonResult?.token.token, { path: '/' });

        if (typeof jsonResult?.staff !== 'undefined') {
          setCookie('user_type', 'staff', { path: '/' });
          setUser(jsonResult?.staff);
        } else {
          setCookie('user_type', 'user', { path: '/' });
          setUser(jsonResult?.user);
        }

        history.push('/'); // redirect to homepage
      })
      .catch((err) => {
        if (typeof err !== 'string') {
          err = 'Network error';
        }

        //alert(err || 'Something went wrong!');
        //change loading from true to false
        
        toast.warning("Invalid email/password");
        
        setIsLoading(false);
        setIsAuthenticated(false);
        removeCookie('token');
        removeCookie('user_type');
        setUser(null);
      });
  };

  const logout = () => {
    let url = cookies.user_type === 'staff' ? '/admin/auth/logout' : '/user/auth/logout';
    setIsLoading(true);
    authLogout(url, cookies.token)
      .then(async (response) => {
        const jsonResult = await response.json();
        console.log('jsonResult', jsonResult);

        if (jsonResult?.code !== 200) {
          throw jsonResult?.errorTrace?.[0]?.message || jsonResult?.error;
        }
        console.log('Logout successful!');
      })
      .catch((err) => {
        if (typeof err !== 'string') {
          err = 'Network error';
        }
        console.log(err || 'Something went wrong!');
      })
      .finally(() => {
        //change loading from true to false
        setIsLoading(false);
        setIsAuthenticated(false);
        removeCookie('token');
        //removeCookie('user_type');
        setUser(null);

        if (cookies.user_type === 'staff') {
          history.push('/staff/login'); // admin login page
        } else {
          history.push('/login'); // user login page
        }
      });
  };

  const signUp = (credentials) => {
    let url = history.location.pathname === '/signup' ? '/user/auth/registration' : '/admin/auth/registration';

    setIsLoading(true);
    authSignUp(url, credentials)
      .then(async (response) => {
        const jsonResult = await response.json();
        console.log('jsonResult', jsonResult);

        if (jsonResult?.code !== 200) {
        if(jsonResult?.errorTrace?.errors?.[0]?.message){
          throw `${jsonResult?.errorTrace?.errors?.[0]?.field}, ${jsonResult?.errorTrace?.errors?.[0]?.message}`
        }
          throw  jsonResult?.error || "Something went wrong!";
        }
         
        alert("Registered Successfully, your account is currently under review.")
        history.push('/login'); // redirect to homepage
         
      })
      .catch((err) => {
        if (typeof err !== 'string') {
          err = "Something went wrong!";
        }
        toast.error(err || 'Something went wrong!') 
      }).finally(()=>{
        setIsLoading(false); 
      });
  };

  return (<AuthContext.Provider value={{ isAuthenticated, isLoading, login, logout, signUp, user, checkAuth }}>
                <ToastContainer></ToastContainer>
                {children}
          </AuthContext.Provider>);
}

// Auth.propTypes = {
//     children: PropTypes.oneOfType([
//         PropTypes.func,
//         PropTypes.array
//     ])
// }

// ref: https://gist.github.com/EduVencovsky/f8f6c275f42f7352571c92a59309e31d
