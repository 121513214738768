import React from 'react';
import * as Icon from 'react-feather';
const navigationConfig = [
  // {
  //   id: 'home',
  //   title: 'Home',
  //   type: 'item',
  //   icon: <Icon.Home size={20} />,
  //   // permissions: ['admin', 'editor'],
  //   permissions: ['staff', 'user'],
  //   navLink: '/',
  // },
  {
    id: 'userDashboard',
    title: 'Dashboard',
    type: 'item',
    icon: <Icon.Home size={20} />,
    // permissions: ['admin', 'editor'],
    permissions: ['user'],
    navLink: '/user/dashboard',
  },
  {
    id: 'staffDashboard',
    title: 'Dashboard',
    type: 'item',
    icon: <Icon.Home size={20} />,
    // permissions: ['admin', 'editor'],
    permissions: ['staff'],
    navLink: '/staff/dashboard',
  },
  // {
  //   id: "page2",
  //   title: "Page 2",
  //   type: "item",
  //   icon: <Icon.File size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/page2",
  // },
  {
    id: 'staffOrders',
    title: 'Orders',
    type: 'item',
    icon: <Icon.Database size={20} />,
    // permissions: ['admin', 'editor'],
    permissions: ['staff'],
    navLink: '/staff/orders',
  },
  {
    id: 'userOrders',
    title: 'Orders',
    type: 'item',
    icon: <Icon.Database size={20} />,
    // permissions: ['admin', 'editor'],
    permissions: ['user'],
    navLink: '/orders',
  },
  {
    id: 'fund',
    title: 'Fund Requests',
    type: 'item',
    icon: <Icon.DollarSign size={20} />,
    // permissions: ['admin', 'editor'],
    permissions: ['user'],
    navLink: '/fund',
  },
  {
    id: 'staffFund',
    title: 'Fund Requests',
    type: 'item',
    icon: <Icon.DollarSign size={20} />,
    // permissions: ['admin', 'editor'],
    permissions: ['staff'],
    navLink: '/staff/fund',
  },
  {
    id: 'report',
    title: 'Report',
    type: 'item',
    icon: <Icon.Download size={20} />,
    // permissions: ['admin', 'editor'],
    permissions: ['user'],
    navLink: '/user/report',
  },
  {
    id: 'commission',
    title: 'Commission',
    type: 'item',
    icon: <Icon.Percent size={20} />,
    // permissions: ['admin', 'editor'],
    permissions: ['user'],
    navLink: '/commission',
  },
  {
    id: 'webhook',
    title: 'WebHook',
    type: 'item',
    icon: <Icon.Layout size={20} />,
    // permissions: ['admin', 'editor'],
    permissions: ['user'],
    navLink: '/webhook',
  },
  {
    id: 'package',
    title: 'Package',
    type: 'item',
    icon: <Icon.Package size={20} />,
    // permissions: ['admin', 'editor'],
    permissions: ['staff'],
    navLink: '/package',
  },
  {
    id: 'logs',
    title: 'Logs',
    type: 'item',
    icon: <Icon.Layers size={20} />,
    // permissions: ['admin', 'editor'],
    permissions: ['staff'],
    navLink: '/logs',
  },
  // {
  //   id: 'recharge',
  //   title: 'Recharge',
  //   type: 'item',
  //   icon: <Icon.Target size={20} />,
  //   // permissions: ['admin', 'editor'],
  //   permissions: ['staff', 'user'],
  //   navLink: '/recharge',
  // },
  {
    id: 'settings',
    title: 'Settings',
    type: 'item',
    icon: <Icon.Settings size={20} />,
    // permissions: ['admin', 'editor'],
    permissions: ['user'],
    navLink: '/settings',
  },
  // {
  //   id: 'adminLogout',
  //   title: 'Log Out',
  //   type: 'item',
  //   icon: <Icon.File size={20} />,
  //   // permissions: ['admin', 'editor'],
  //   permissions: ['staff'],
  //   navLink: '/staff/logout',
  // },

  {
    id: 'user',
    title: 'User',
    type: 'item',
    icon: <Icon.Smile size={20} />,
    // permissions: ['admin', 'editor'],
    permissions: ['staff'],
    navLink: '/users',
  },

  {
    id: 'staff',
    title: 'Staff',
    type: 'collapse',
    icon: <Icon.User size={20} />,
    // permissions: ['admin'],
    permissions: ['staff'],
    children: [
      {
        id: 'list',
        title: 'List',
        type: 'item',
        icon: <Icon.Users size={12} />,
        // permissions: ['admin'],
        permissions: ['staff'],
        navLink: '/staff/list',
      },
      {
        id: 'add',
        title: 'Add',
        type: 'item',
        icon: <Icon.UserPlus size={12} />,
        // permissions: ['admin'],
        permissions: ['staff'],
        navLink: '/staff/create',
      },
    ],
  },
  // {
  //   id: 'walletTransactions',
  //   title: 'Wallet Transactions',
  //   type: 'item',
  //   icon: <Icon.CreditCard size={20} />,
  //   // permissions: ['admin', 'editor'],
  //   permissions: ['staff', 'user'],
  //   navLink: '/walletTransactions',
  // },
  // {
  //   id: 'walletStatement',
  //   title: 'Wallet Statement',
  //   type: 'item',
  //   icon: <Icon.List size={20} />,
  //   permissions: ['staff', 'user'],
  //   navLink: '/walletStatement',
  // },

  {
    id: 'staffCompanyMonthRecord',
    title: 'Month Record',
    type: 'item',
    icon: <Icon.List size={20} />,
    permissions: ['staff'],
    navLink: '/staff/company/month/record',
  },

  {
    id: 'staffCompanyMonthbillp2p',
    title: 'Month Bill p2p',
    type: 'item',
    icon: <Icon.List size={20} />,
    permissions: ['staff'],
    navLink: '/staff/company/month/bill/p2p',
  },
  {
    id: 'staffCompanyMonthbillp2a',
    title: 'Month Bill p2a',
    type: 'item',
    icon: <Icon.List size={20} />,
    permissions: ['staff'],
    navLink: '/staff/company/month/bill/p2a',
  },
  {
    id: 'logout',
    title: 'Log Out',
    type: 'item',
    icon: <Icon.LogOut size={20} />,
    // permissions: ['admin', 'editor'],
    permissions: ['user', 'staff'],
    navLink: '/logout',
  },
];

export default navigationConfig;
